<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :md="8" :sm="24">
            <a-form-item
              label="合同号"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.contractNo" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              label="项目名称"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.name" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button
          v-hasPermission="['project:add']"
          type="primary"
          ghost
          @click="add"
          >新增</a-button
        >
        <a-button
          style="margin-left: 50px"
          @click="exportExcel"
          v-hasPermission="['project:export']"
          >导出Excel</a-button
        >
        <a-button
          style="margin-left: 50px"
          @click="downloadTemplate"
          v-hasPermission="['project:export']"
          >模板下载</a-button
        >
        <a-upload
          :fileList="fileList"
          :remove="handleRemove"
          :disabled="fileList.length === 1"
          :beforeUpload="beforeUpload"
        >
          <a-button> <a-icon type="upload" /> 选择.xlsx文件 </a-button>
          <a-button
            class="button-area"
            @click="handleUpload"
            :disabled="fileList.length === 0"
            :loading="uploading"
          >
            {{ uploading ? "导入中" : "导入Excel" }}
          </a-button>
        </a-upload>
      </div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.projectId"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        @change="handleTableChange"
        bordered
        :scroll="{ x: 900 }"
      >
        <template slot="remark" slot-scope="text">
          <a-popover placement="topLeft">
            <template slot="content">
              <div style="max-width: 200px">{{ text }}</div>
            </template>
            <p style="width: 200px; margin-bottom: 0">{{ text }}</p>
          </a-popover>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-icon
            v-hasPermission="['project:update']"
            type="setting"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            @click="edit(record)"
            title="修改项目"
          ></a-icon>
          <a-badge
            v-hasNoPermission="['project:update']"
            status="warning"
            text="无权限"
          ></a-badge>
        </template>
      </a-table>
      <import-result
        @close="handleClose"
        :importData="importData"
        :errors="errors"
        :times="times"
        :importResultVisible="importResultVisible"
      >
      </import-result>
      <!-- 新增项目 -->
      <project-add
        @close="handleProjectAddClose"
        @success="handleProjectAddSuccess"
        :projectAddVisiable="projectAddVisiable"
      >
      </project-add>
      <!-- 修改项目 -->
      <project-edit
        ref="projectEdit"
        @close="handleProjectEditClose"
        @success="handleProjectEditSuccess"
        :status="this.status"
        :projectEditVisiable="projectEditVisiable"
      >
      </project-edit>
    </div>
  </a-card>
</template>
<script>
import ProjectAdd from "./ProjectAdd";
import ProjectEdit from "./ProjectEdit";
import ImportResult from "./ImportResult";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "project",
  components: { ProjectAdd, ProjectEdit, ImportResult },
  data() {
    return {
      fileList: [],
      importData: [],
      times: "",
      errors: [],
      uploading: false,
      importResultVisible: false,
      advanced: false,
      dataSource: [],
      selectedRowKeys: [],
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },
      queryParams: {},
      projectAddVisiable: false,
      projectEditVisiable: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.project_status,
    }),
    columns() {
      return [
        {
          title: "ID",
          dataIndex: "projectId",
          width: 80,
        },
        {
          title: "合同号",
          dataIndex: "contractNo",
          width: 150,
        },
        {
          title: "项目名称",
          dataIndex: "name",
          width: 300,
        },
        {
          title: "产品线",
          width: 100,
          dataIndex: "productLine",
        },
        {
          title: "产品型号",
          width: 100,
          dataIndex: "productVersion",
        },
        {
          title: "数量",
          dataIndex: "amount",
          width: 70,
        },
        {
          title: "标准服务天数",
          dataIndex: "standardServiceDays",
          width: 70,
        },
        {
          title: "发货日期",
          dataIndex: "actualDate",
          width: 110,
          customRender: (text, row, index) => {
            return text == undefined ? "" : moment(text).format("YYYY-MM-DD");
          },
        },
        {
          title: "SAP订单",
          width: 110,
          dataIndex: "sapNo",
        },
        {
          title: "省",
          width: 100,
          dataIndex: "province",
        },
        {
          title: "市",
          width: 100,
          dataIndex: "city",
        },
        {
          title: "现场地址",
          width: 200,
          dataIndex: "address",
        },
        {
          title: "工厂",
          dataIndex: "factory",
          width: 200,
        },
        {
          title: "开箱检查",
          dataIndex: "checkedAcceptedTime",
          customRender: (text, row, index) => {
            if (text == undefined) {
              return ""
            }
            return <div><a-tag color='orange'>{row.checkedAcceptedUsername}</a-tag><a-tag color='purple'>{text}</a-tag></div> ;
          },
          width: 250,
        },
        {
          title: "安装调试",
          dataIndex: "installationCommissioningTime",
          customRender: (text, row, index) => {
            if (text == undefined) {
              return ""
            }
            return <div><a-tag color='orange'>{row.installationCommissioningUsername}</a-tag><a-tag color='purple'>{text}</a-tag></div> ;
          },
          width: 250,
        },
        {
          title: "项目送电",
          dataIndex: "panelEnergizedTime",
          customRender: (text, row, index) => {
            if (text == undefined) {
              return ""
            }
            return <div><a-tag color='orange'>{row.panelEnergizedUsername}</a-tag><a-tag color='purple'>{text}</a-tag></div> ;
          },
          width: 250,
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (text, row, index) => {
            for (let _index in this.status) {
              let key = this.status[_index].keyy;
              if (text === key) {
                let val = this.status[_index].valuee;
                let color = this.status[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
          width: 100,
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          width: 100,
        },
      ];
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleClose() {
      this.importResultVisible = false;
    },
    downloadTemplate() {
      this.$download("project/template", {}, "Project_ImportTemplate.xlsx");
    },
    handleRemove(file) {
      if (this.uploading) {
        this.$message.warning("文件导入中，请勿删除");
        return;
      }
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleUpload() {
      const { fileList } = this;
      const formData = new FormData();
      formData.append("file", fileList[0]);
      this.uploading = true;
      this.$upload("project/import", formData)
        .then((r) => {
          let data = r.data.data;
          if (data.data != undefined && data.data.length) {
            this.fetch();
          }
          this.importData = data.data == undefined ? [] : data.data;
          this.errors = data.error == undefined ? [] : data.error;
          this.times = data.time / 1000;
          this.uploading = false;
          this.fileList = [];
          this.importResultVisible = true;
        })
        .catch((r) => {
          console.error(r);
          this.uploading = false;
          this.fileList = [];
        });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleProjectAddSuccess() {
      this.projectAddVisiable = false;
      this.$message.success("新增项目成功");
      this.search();
    },
    handleProjectAddClose() {
      this.projectAddVisiable = false;
    },
    add() {
      this.projectAddVisiable = true;
    },
    handleProjectEditSuccess() {
      this.projectEditVisiable = false;
      this.$message.success("修改项目成功");
      this.search();
    },
    handleProjectEditClose() {
      this.projectEditVisiable = false;
    },
    edit(record) {
      this.$refs.projectEdit.setFormValues(record);
      this.projectEditVisiable = true;
    },
    exportExcel() {
      let projectIds = "";
      if (this.selectedRowKeys.length) {
        projectIds = this.selectedRowKeys.join(",");
      }
      this.$export("project/excel?projectIds=" + projectIds, {
        ...this.queryParams,
      });
    },
    search() {
      this.selectData({
        ...this.queryParams,
      });
    },
    reset() {
      // 取消选中
      this.selectedRowKeys = [];
      // 重置分页
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      if (this.paginationInfo) {
        this.paginationInfo.current = this.pagination.defaultCurrent;
        this.paginationInfo.pageSize = this.pagination.defaultPageSize;
      }
      this.paginationInfo = null;
      // 重置查询参数
      this.queryParams = {};
      this.fetch();
    },
    handleTableChange(pagination, filters, sorter) {
      this.paginationInfo = pagination;
      this.fetch({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }
      this.$get("project", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
    selectData(params = {}) {
      this.loading = true;
      // 如果分页信息为空，则设置为默认值
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      this.$refs.TableInfo.pagination.pageSize = this.pagination.defaultPageSize;
      params.pageSize = this.pagination.defaultPageSize;
      params.pageNum = this.pagination.defaultCurrent;
      this.$get("project", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>